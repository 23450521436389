@charset "UTF-8";

.download-tooltip {
  position: absolute;
  background: #fff;
  top: 50px;
  width: 240px;
  height: 400px;
  border-radius: 5px;
  left: -80px;
  z-index: 1;
  box-shadow: 1px 1px 10px rgba(0,0,0,.2);

  ul {
    margin: 10px 15px;
    list-style-type: none;
    padding: 0;

    li {
      list-style-type: none;
      margin: 0;
      padding: 0;
      height: 60px;
      line-height: 60px;
      text-align: center;
      border-bottom: 1px solid #E6E8EB;

      a {
        text-decoration: none;
        display: inline-block;
        width: 180px;
        height: 42px;
        line-height: 42px;
        background: #fff;
        border-radius: 4px;
        color: #1D212C;
        text-align: left;
        padding-left: 30px;

        i.icon {
          display: inline-block;
          width: 20px;
          height: 20px;
          vertical-align: middle;
        }

        &:hover {
          color: #fff;
          background: rgba(96,80,255,1);
        }
      }

      &.apk {
        a {
          i.icon {
            background: url("~@public/assets/web/ic_android_apk_20_n@2x.png") no-repeat center;
            background-size: contain;
          }

          &:hover {
            i.icon {
              background: url("~@public/assets/web/ic_android_apk_20_s@2x.png") no-repeat center;
              background-size: contain;
            }
          }
        }
      }

      &.ios {
        a {
          i.icon {
            background: url("~@public/assets/web/ic_apple_20_n@2x.png") no-repeat center;
            background-size: contain;
          }

          &:hover {
            i.icon {
              background: url("~@public/assets/web/ic_apple_20_s@2x.png") no-repeat center;
              background-size: contain;
            }
          }
        }
      }

      &.android {
        a {
          i.icon {
            background: url("~@public/assets/web/ic_google_20_n@2x.png") no-repeat center;
            background-size: contain;
          }

          &:hover {
            i.icon {
              background: url("~@public/assets/web/ic_google_20_s@2x.png") no-repeat center;
              background-size: contain;
            }
          }
        }
      }
    }

    .qr {
      width: 150px;
      height: 150px;
      background: url("~@public/assets/web/qr.png") no-repeat center;
      background-size: contain;
      margin: 20px auto 10px;
    }

    .tip {
      color: #636B82;
      text-align: center;
      font-size: 12px;
    }
  }

  &.top::before {
    content: '';
    position: absolute;
    top: -20px;
    left: 0;
    right: 0;
    width: 0;
    margin: 0 auto;
    border: 10px solid transparent;
    border-bottom: 10px solid #fff;
  }

  &.right::before {
    content: '';
    position: absolute;
    bottom: 130px;
    right: -20px;
    height: 0;
    margin: 0 auto;
    border: 10px solid transparent;
    border-left: 10px solid #fff;
  }
}
