.nav-end {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 200px;

  .navbar-nav {
    > li {
      > a {
        text-decoration: underline;
      }
    }
  }

  .container {
    margin-top: 100px;

    li {
      a {
        padding: 5px 10px;
      }

      &.line {
        a {
          color: #ccc;

          &:hover {
            background: transparent;
            cursor: default;
          }
        }
      }
    }
  }
}

#fp-nav ul li a span, .fp-slidesNav ul li a span {
  background: #fff;
}

#lang-select {
  float: left;
  #dropdownMenu1 {
    padding: 5px 10px;
    border: none;
    // color: #999;
    color: #337ab7;
    background-color: transparent;
  }
}

#mico-navbar-collapse-1 {
  ul {
    li {
      a:hover {
        background-color: transparent;
      }
    }
  }
}
