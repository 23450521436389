.nav-end {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 200px;
}
.nav-end .navbar-nav > li > a {
  text-decoration: underline;
}
.nav-end .container {
  margin-top: 100px;
}
.nav-end .container li a {
  padding: 5px 10px;
}
.nav-end .container li.line a {
  color: #ccc;
}
.nav-end .container li.line a:hover {
  background: transparent;
  cursor: default;
}
#fp-nav ul li a span,
.fp-slidesNav ul li a span {
  background: #fff;
}
#lang-select {
  float: left;
}
#lang-select #dropdownMenu1 {
  padding: 5px 10px;
  border: none;
  color: #337ab7;
  background-color: transparent;
}
#mico-navbar-collapse-1 ul li a:hover {
  background-color: transparent;
}
