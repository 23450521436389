@charset "UTF-8";

html {
  font-size: 62.5%;
  &:lang(ar) {
    direction: rtl;
  }
}

@media all and (max-width: 1120px) {
  html {
    font-size: 65%;
  }
}

@media all and (max-width: 980px) {
  html {
    font-size: 60%;
  }
}

@media all and (max-width: 768px) {
  html {
    font-size: 55%;
  }

  #fp-nav {
    display: none;
  }
}

@media all and (max-width: 540px) {
  html {
    font-size: 50%;
  }
}

@media all and (max-width: 350px) {
  html {
    font-size: 45%;
  }
}

@media all and (max-width: 320px) {
  html {
    font-size: 40%;
  }
}
